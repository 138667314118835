// Tablets & Smaller
@media (max-width: 800px) {
  #header-home {
    height: 30rem;

    .header-content {
      padding-top: 5rem;
    }
  }

  #success {
    div {
      padding: 1rem 0rem;

      h1 {
        font-size: 2rem;
      }
    }
  }
}

// Smartphones
@media (max-width: 500px) {
  #main-nav {
    flex-direction: column;
    align-items: center;

    li {
      padding: 1rem;
    }
  }

  #header {
    &-home {
      height: 10rem;
      border-bottom: 3px solid $main-color;
      background-position: 20% 30%;
      .header-content {
        display: none;
      }
    }

    &-inner {
      height: 10rem;
    }
  }

  //   Stack Grid Columns
  #home-a .specials,
  #home-b .stats,
  #home-c .process,
  #about-d .testimonials,
  #contact-b .contact-info,
  .items {
    grid-template-columns: 1fr;
  }
  // Home
  #home {
    &-a {
      .specials div {
        border-bottom: 1px $light-color solid;
        padding-bottom: 1rem;

        &:last-child {
          border: none;
          padding-bottom: 0;
        }
      }
    }

    &-b {
      .stats div {
        padding: 2rem 0 1rem 0;
      }
    }
  }

  //   About
  #about {
    &-a {
      .about-info {
        grid-template-areas:
          "bioimage"
          "bio"
          "aw1"
          "aw2"
          "aw3";
      }
    }

    &-c {
      display: none;
    }
  }

  //   Contact
  #contact {
    &-a {
      .text-fields {
        grid-template-areas:
          "name"
          "subject"
          "email"
          "phone"
          "message";
      }
    }

    &-b {
      div {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px #555 solid;

        &:last-child {
          border: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }
      }
    }

    &-c {
      h1 {
        font-size: 2rem;
      }
    }
  }

  #success {
    div {
      padding: 1rem 0rem;

      h1 {
        font-size: 2rem;
      }
    }
  }

  // Footer
  #main-footer {
    height: 7rem;
    .footer-content {
      flex-direction: column;
      padding: 1rem;
      height: 5rem;
    }
  }
}

// Landscape
@media (max-height: 580px) {
  #header-home {
    .header-content {
      padding-top: 3rem;
    }
  }
}

@media (max-height: 330px) {
  #header-home {
    .header-content {
      h1 {
        font-size: 2rem;
      }
    }
  }
}
